// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/molecules/Tableau.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/molecules/Tableau.tsx");
  import.meta.hot.lastModified = "1730399200000.5757";
}
// REMIX HMR END

import { useNonce } from '~/contexts/NonceContext';

// Define the custom web component type and the parameters we use.
// Ref: https://coryrylan.com/blog/how-to-use-web-components-with-typescript-and-react
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-enable @typescript-eslint/no-namespace */

export function TableauScript() {
  _s();
  const nonce = useNonce();
  return <script type='module' src='https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js' nonce={nonce} />;
}
_s(TableauScript, "fTu1PJvfUhwKAAPh1vkoJap6Hoo=", false, function () {
  return [useNonce];
});
_c = TableauScript;
export function TableauEmbed(props) {
  return <tableau-viz {...props} />;
}
_c2 = TableauEmbed;
var _c, _c2;
$RefreshReg$(_c, "TableauScript");
$RefreshReg$(_c2, "TableauEmbed");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;